import React, { useState } from "react";
import { t } from "i18next";
import httpclientService from "../../services/httpclient.service";
import { OmisButtonNewReport, OmisDropDownWithDynamicComponents } from "../shared/OmisButtons";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router';
import { Grid } from "@mui/material";
import {
    mdiArchive, mdiClockTimeThreeOutline, mdiFileDocument, mdiFileDocumentMultiple, mdiInboxFull, //mdiBell, mdiEmail, mdiMinusBox, mdiHome, mdiInboxArrowUp,
    mdiPlusBox, mdiAccount, mdiRead, mdiEmailMarkAsUnread, mdiLinkVariant
} from "@mdi/js";
import { OmisDialog } from "../shared/OmisDisplays";
import { OmisDropdownMultiselectV2 } from "../shared/OmisDropdowns";
import { OmisGrid } from "../shared/OmisGrids";
import { OmisSearchBar } from "../shared/OmisInputs";
import { ChangeResponsiblePerson, ReportEdit } from "./ReportEdit";
import { PrepareModalTitle } from "./ReportsDetails";
import { AddNote } from "./AddNote";
import { AddDocuments } from "./AddDocuments";
import AuthService from "../../services/auth.service";
import { TRole } from "../../constants/RoleConstants";
import { AddLinkedReport } from "../inspections/AddLinkedReport";
import { OmisCol, OmisContainer, OmisRow } from "../shared/OmisLayouts";

var addNoteTitle = "", addLinkedReportsTitle="", editTitle = "", changeResponsiblePerson = "Verantwortlichen ändern", markAsRead = "markasread";

export const handleReportArchive = (reportIDs) => {

    var values = Array.isArray(reportIDs) ? reportIDs : [reportIDs];

    return httpclientService.post(`/api/reports/archivereport`, { ids: values })
        .then((response) => {
            return response;
        });
}

export default function Reports(props) {
    var { state } = useLocation();

    var startTime = new Date();

    const [modalHeight, setModalHeight] = useState(600);
    const [modalWidth, setModalWidth] = useState("md");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [changeCounter, setChangeCounter] = useState(0);
    const [filterValue, setFilterValue] = useState("");
    const [user, setUser] = useState(AuthService.getCurrentUser());

    const [selectedRowID, setSelectedRowID] = useState(0);
    const [selectedRowIDs, setSelectedRowIDs] = useState([]);
    const navigate = useNavigate();

    addNoteTitle = `${t("Note")}`; 
    editTitle = t("Action_Edit");
    addLinkedReportsTitle = `${t("LinkedFaultReports")}`;

    var url = "/api/reports/getreportlist";

    if (props.removereportfromlist) {
        url = `${url}?removereportfromlist=${props.removereportfromlist}`;
    }

    if (props.objectid) {
        url = `${url}?objectid=${props.objectid}`;
    }

    if (props.equipid) {
        url = `${url}?equipid=${props.equipid}`;
    }

    if (props.parentfaultreportid) {
        url = `${url}?parentfaultreportid=${props.parentfaultreportid}`;
    }

    if (props.projectid) {
        url = `${url}?projectid=${props.projectid}`;
    }

    function handleAddObjectClick(rowid) {
        console.log("Add Object Row ID:" + rowid);
    }

    function handleAddReportClick(rowid) {
        console.log("Add Report Row ID:" + rowid);
    }

    function handleModalOpen() {
        setModalOpen(true);
    }

    const handleModalCancel = (refresh) => {
        setModalOpen(false);
        setModalTitle(null);
        setModalContent(null);
    };

    const handleModalClose = (refresh) => {
        setModalOpen(false);
        setModalTitle(null);
        setModalContent(null);
        setSelectedRowID(0);
        if (refresh === 1) {
            setChangeCounter((prevState) => prevState + 1);
        }
    };

    async function handleMarkAsRead(rowid) {
        return httpclientService.post(`/api/reports/markasread?userID=${user.id}&reportid=${rowid}`)
            .then((response) => {
                return handleModalClose(1);
            });
    }

    async function handleMarkAsUnread(rowid) {
        return httpclientService.post(`/api/reports/markasunread?userID=${user.id}&reportid=${rowid}`)
            .then((response) => {
                return handleModalClose(1);
            });
    }

    const handleModalOpenForDropdownMenuActions = (title, content, rowid,rowVals) => {
        setSelectedRowID(rowid);
        setModalTitle(<PrepareModalTitle reportid={rowid} title={title} />);
        var modalHeight = 600,modalWidth = "md";

        if (content === null || content === undefined) {
            switch (title) {
                case editTitle:
                    modalWidth = "lg";
                    modalHeight = 800;
                    content = <ReportEdit handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} selectedRowID={rowid} {...props} />;
                    break;
                case t("Documents"):
                    content = <AddDocuments closeAction={handleModalClose} handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} reportid={rowid} {...props} />;
                    break;
                case addNoteTitle:
                    modalWidth = "sm";
                    content = <AddNote onCancel={handleModalCancel } onClose={handleModalClose} reportid={rowid} {...props} />;
                    break;
                case addLinkedReportsTitle:
                    modalWidth = "lg";
                    modalHeight = 800;
                    var state = { ...props, reportid: rowid };
                    content = <AddLinkedReport saveUrl={"/api/reports/savelinkreports"} handleCancel={handleModalCancel} onClose={handleModalClose} state={state} selecteableRowCount={20-rowVals.linkedReportsCount} />;
                    break;
                case changeResponsiblePerson:
                    modalWidth = "sm";
                    modalHeight = 250;
                    content = <ChangeResponsiblePerson handleModalCancel={handleModalCancel} handleModalClose={handleModalClose} faultReportID={rowid} {...props} />
                    break;
                default:
                    break;
            }
        }

        setModalHeight(modalHeight);
        setModalWidth(modalWidth);
        setModalContent(content);
        handleModalOpen();
    };

    const subMenuItems = [
        {
            iconpath: mdiLinkVariant,
            action: handleModalOpenForDropdownMenuActions.bind(this, addLinkedReportsTitle, null),
            icontext: addLinkedReportsTitle,
        },
        {
            action: handleModalOpenForDropdownMenuActions.bind(this, t("Documents"), null),
            iconpath: mdiFileDocument,
            icontext: t("Documents"),
            disableItem: false
        },
        // {
        //     action: handleAddObjectClick,
        //     iconpath: mdiHome,
        //     icontext: "Add object",
        //     disableItem: false
        // },
        // {
        //     action: handleAddReportClick,
        //     iconpath: mdiInboxFull,
        //     icontext: "Add report",
        //     disableItem: false
        // },
        {
            iconpath: mdiFileDocumentMultiple,
            action: handleModalOpenForDropdownMenuActions.bind(this, addNoteTitle, null),
            icontext: addNoteTitle,
        }
    ]

    function handleMultiMark(ids, isUnread) {
        if (isUnread) {
            ids.map((id) => handleMarkAsRead(id));            
        } else {
            ids.map((id) => handleMarkAsUnread(id));
        }
    }

    function handleChangeResponsiblePersonMulti(ids,row) {
        setSelectedRowID(row);
        setModalTitle(<PrepareModalTitle title={changeResponsiblePerson} />);
        setModalHeight(200);
        setModalWidth("sm");
        setModalContent(<ChangeResponsiblePerson handleModalCancel={handleModalClose} handleModalClose={handleModalClose} selectedRowIDs={ids} {...props} />);
        handleModalOpen();
    }

    function handleBulkActions(menu) {

        switch (menu) {
            case t("Action_Archive"):
                handleReportArchive(selectedRowIDs).then(() => {
                    setChangeCounter((prevState) => prevState + 1);
                });
                break;
            case t("MarkAsRead"):
                handleMultiMark(selectedRowIDs,true).then(() => {
                    setChangeCounter((prevState) => prevState + 1);
                });
                break;
            case t("MarkAsUnread"):
                handleMultiMark(selectedRowIDs, false).then(() => {
                    setChangeCounter((prevState) => prevState + 1);
                });
                break;
            case t("LinkFaultReports"):
                var state = { ...props, reportids: selectedRowIDs };

                setModalHeight(800);
                setModalWidth("lg");
                setModalContent(<AddLinkedReport saveUrl={"/api/reports/savelinkreports"} reportids={selectedRowIDs} handleCancel={handleModalCancel} onClose={handleModalClose} state={state} selecteableRowCount={20} />);
                handleModalOpen();
                break;
            default:
                break;
        }
    }

    const dropDownMenuItems = [
        // {
        //     action: null,
        //     iconpath: mdiFileDocumentMultiple,
        //     icontext: "Dublicate",
        //     expand: false,
        //     subMenuItems: null,
        //     disableItem: false
        // },
        {
            action: null,
            iconpath: mdiPlusBox,
            icontext: t("Action_Add"),
            expand: false,
            subMenuItems: subMenuItems,
            disableItem: false,
            disableMultiSelect: true,
        }, {
            action: { singleAction: handleModalOpenForDropdownMenuActions.bind(this, changeResponsiblePerson, null), multiAction: handleChangeResponsiblePersonMulti },
            iconpath: mdiAccount,
            icontext: changeResponsiblePerson,
            hideMenuItem: !AuthService.hasRole(TRole.ChangeAssignedTechnician),
            menuKeyword: "changeResponsiblePerson"
        }, {
            action: { handleMarkAsRead: handleMarkAsRead, handleMarkAsUnread: handleMarkAsUnread, handleMultiMark: handleMultiMark },
            iconpath: mdiRead,
            icontext: markAsRead
        },
        // {
        //     action: () => { console.log("SetReminder") },
        //     iconpath: mdiBell,
        //     icontext: "Set Reminder",
        //     expand: false,
        //     subMenuItems: null,
        //     disableItem: false
        // },
        // {
        //     action: null,
        //     iconpath: mdiInboxArrowUp,
        //     icontext: "Export CSV",
        //     disableItem: false
        // },
        // {
        //     action: null,
        //     iconpath: mdiEmail,
        //     icontext: "Send email",
        //     disableItem: false
        // },
        // {
        //     action: null,
        //     iconpath: mdiMinusBox,
        //     icontext: "Cancel report",
        //     disableItem: false
        // },
        // {
        //     action: (rowid) => {
        //         handleReportArchive(rowid).then((response) => {
        //             if (response) {
        //                 navigate(0);
        //             }
        //         });
        //     },
        //     iconpath: mdiArchive,
        //     icontext: t("Action_Archive"),
        //     disableItem: false
        //     hideMenuItem: !AuthService.hasRole(TRole.FaultReportArchive)
        // },
    ]

    const bulkActions = [
        {
            onClick: handleBulkActions.bind(this, t("MarkAsRead")),
            subMenuIcon: mdiRead,
            menuName: t("MarkAsRead"),
        },
        {
            onClick: handleBulkActions.bind(this, t("MarkAsUnread")),
            subMenuIcon: mdiEmailMarkAsUnread,
            menuName: t("MarkAsUnread"),
        },
        {
            onClick: handleBulkActions.bind(this, t("LinkFaultReports")),
            subMenuIcon: mdiInboxFull,
            menuName: t("LinkFaultReports"),
        },
        {
            onClick: handleBulkActions.bind(this, t("Action_Archive")),
            subMenuIcon: mdiArchive,
            menuName: t("Action_Archive"),
            hideMenuItem: !AuthService.hasRole(TRole.FaultReportArchive)
        },
    ];

    return (
        <OmisRow>
            <Grid container item xs={12}>
			<OmisGrid
				headerText={t("FaultReports")}
				apiUrl={url}
				newButton={AuthService.hasRole(TRole.FaultReportWrite) ? < OmisButtonNewReport id={ "newReportButton"}  component={RouterLink} to={"/reports/create/"} /> : null}
				reportsgrid
                actiondropdown={props.hideActionDropdown ? null : AuthService.hasRole(TRole.FaultReportEdit) ? { edit: handleModalOpenForDropdownMenuActions.bind(this, t("Action_Edit"), null), dropDownMenuItems: dropDownMenuItems } : { dropDownMenuItems: dropDownMenuItems }}
				annunciatorDropDownHasItems={[]}
				equiClassesDropDownHasItems={[]}
				equisDropDownHasItems={[]}
				equiTypesDropDownHasItems={[]}
				objectsDropDownHasItems={[]}
				statusDropDownHasItems={[]}
				withDueTime={true}
				startTime={startTime}
				showCheckBox={props.showCheckBox ?? true}
                    rowSelectionCallback={(e) => {
                        if (props.rowSelectionCallback) {
                            props.rowSelectionCallback(e);
                        }
                        setSelectedRowIDs(e);
                    }}
				change={changeCounter}
                setFilterVal={state && state.filterValue ? state.filterValue : null}
                bulkactions={bulkActions }
                    selectedRows={selectedRowIDs}
				{...props}
			/>

			<OmisDialog onClose={handleModalClose} open={modalOpen}
				maxWidth={modalWidth}
				title={modalTitle}
				content={modalContent}
				contentHeight={modalHeight}
                />
            </Grid>
		</OmisRow>
		
    );
}

export function FilterTab(props) {

    return (
        <OmisContainer fluid>
            <OmisRow>
            {
                props.handleSearchBarValChange || props.getSearchResultItems ?
                    <OmisCol sm={props.smallSearchBar ? 4 : 5} lg={props.smallSearchBar ? 3 : 4} >
                        <OmisSearchBar getSearchTerm={props.handleSearchBarValChange} getSearchResultItems={props.getSearchResultItems} listItems={props.listItems} listAttr={props.listAttr} />
                    </OmisCol>
                    :
                    null
            }
            {
                props.objectItems ?
                    <OmisCol xs={2} sx={{marginTop: '0.8em !important'}}>
                        <OmisDropdownMultiselectV2 id="objectDropDown" name={props.objectName} className="" items={props.objectItems} onChange={props.handleDropDownChange.bind(this, props.setSelectedObjectItems)} value={props.selectedObjectItems} labeltext={t("Object")} />
                    </OmisCol>
                    :
                    null
            }
            {
                props.changedStatusItems ?
                    <OmisCol xs={2} sx={{marginTop: '0.8em !important'}}>
                            <OmisDropdownMultiselectV2 id="changedStatusDropDown" name={props.statusName} className="" items={props.changedStatusItems} onChange={props.handleDropDownChangeForChangedStatus.bind(this, props.setSelectedChangedStatusItems)} value={props.selectedChangedStatusItems} labeltext={t("Status")} />
                    </OmisCol>
                    :
                    null
            }
            {
                props.userItems ?
                    <OmisCol xs={2} sx={{marginTop: '0.8em !important'}}>
                        <OmisDropdownMultiselectV2 id="userDropDown" name={props.userName} className="" items={props.userItems} onChange={props.handleDropDownChange.bind(this, props.setSelectedUserItems)} value={props.selectedUserItems} labeltext={t("User")} />
                    </OmisCol>
                    :
                    null
            }
            {
                props.childItemsDateTimePicker ?
                    <OmisCol xs={2} sx={{marginTop: '1em !important'}}>
                        <OmisDropDownWithDynamicComponents horizontal={"center"} menuicon={mdiClockTimeThreeOutline} menutitle={props.timetitle} childitems={props.childItemsDateTimePicker} showdownarrow={1} />
                    </OmisCol>
                    :
                    null
            }
            </OmisRow>
        </OmisContainer>
    );
}

